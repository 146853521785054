import './styles/app.scss';
import './scripts/fusion';

import SmoothScroll from 'smooth-scroll';
import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);

// automatically add all images to the manifest.json
const imagesCtx = require.context('./images', false, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesCtx.keys().forEach(imagesCtx);

const newsScrollOffsetSessionKey = 'news_scroll_offset';

// News scroll offset
const newsScrollOffset = window.sessionStorage.getItem(newsScrollOffsetSessionKey);
window.sessionStorage.removeItem(newsScrollOffsetSessionKey);

if (newsScrollOffset > 0) {
    window.addEventListener('load', () => {
        const el = document.querySelector('.news-navigation');

        if (el) {
            window.scroll(null, el.getBoundingClientRect().y - parseInt(newsScrollOffset, 10));
        }
    });
}

document.addEventListener('DOMContentLoaded', () => {
    // Smooth scroll
    (() => {
        const scroll = new SmoothScroll('a[href*="#"]', {
            header: '.header',
            updateURL: false,
        });

        if (window.location.hash && document.querySelector(window.location.hash)) {
            window.scroll(0,0);
            scroll.animateScroll(document.querySelector(window.location.hash))
        }
    })();

    // Mobile menu trigger
    document.querySelectorAll('[data-mobile-menu-trigger]').forEach(el => {
        el.addEventListener('click', () => {
            document.body.classList.toggle('mobile-menu-active');
        });
    });

    // Mobile menu navigation
    [...document.querySelectorAll('[data-mobile-menu] .mod_navigation')].forEach(navigation => {
        const lis = [...navigation.querySelectorAll('.level_1 > li.submenu')];
        const cssClass = 'submenu-visible';

        lis.forEach(li => {
            if (li.classList.contains('trail')) {
                li.classList.add(cssClass);
            }

            li.children[0].addEventListener('click', e => {
                lis.forEach(el => el !== li && el.classList.remove(cssClass));

                if (!li.classList.contains(cssClass)) {
                    e.preventDefault();
                    li.classList.add(cssClass);
                } else if (e.currentTarget.tagName.toLowerCase() === 'strong') {
                    li.classList.remove(cssClass);
                }
            });
        });
    });

    // Initialize the gallery sliders
    [...document.querySelectorAll('[data-gallery-slider]')].forEach(el => {
        new Swiper(el, {
            breakpoints: {
                768: {
                    slidesPerView: 4,
                },
                1222: {
                    slidesPerView: 7,
                },
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            grabCursor: true,
            slidesPerView: 2,
            threshold: 10,
            watchOverflow: true,
        });
    });

    // Initialize the news sliders
    [...document.querySelectorAll('[data-news-slider]')].forEach(el => {
        new Swiper(el, {
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            centeredSlides: true,
            grabCursor: true,
            initialSlide: window.matchMedia('(min-width: 768px)').matches ? 1 : 0,
            slidesPerView: 'auto',
            spaceBetween: 20,
            threshold: 10,
            watchOverflow: true,
        });
    });

    // Initialize the hotspot elements
    [...document.querySelectorAll('.hotspots')].forEach(wrapper => {
        const recalculate = () => {
            const wrapperSize = wrapper.getBoundingClientRect();
            const cssClass = 'hotspots__hotspot--right-anchor';

            [...wrapper.querySelectorAll('.hotspots__hotspot')].forEach(hotspot => {
                const tooltipSize = hotspot.querySelector('.hotspots__tooltip').getBoundingClientRect();
                const triggerSize = hotspot.querySelector('.hotspots__trigger').getBoundingClientRect();

                if (tooltipSize.width + triggerSize.left > wrapperSize.width + wrapperSize.left) {
                    hotspot.classList.add(cssClass);
                } else {
                    hotspot.classList.remove(cssClass);
                }
            });
        };

        window.addEventListener('resize', recalculate);
        recalculate();
    });

    // Initialize the timelines
    [...document.querySelectorAll('[data-timeline]')].forEach(el => {
        const yearsSlider = new Swiper(el.querySelector('[data-timeline-years-slider]'), {
            centeredSlides: true,
            initialSlide: el.querySelectorAll('[data-timeline-years-slider] .swiper-slide').length - 1,
            slidesPerView: 'auto',
        });

        const years = [...yearsSlider.el.querySelectorAll('[data-timeline-year]')];
        const months = [...yearsSlider.el.querySelectorAll('[data-timeline-month]')];

        const updateYearsSlider = (instance, speed = yearsSlider.speed) => {
            const currentYear = instance.slides[instance.activeIndex].dataset.timelineYear;
            const currentMonth = instance.slides[instance.activeIndex].dataset.timelineMonth
            const yearCssClass = 'timeline__year--active';
            const monthCssClass = 'timeline__year-month--active';

            years.forEach((el, index) => {
                if (el.dataset.timelineYear === currentYear) {
                    yearsSlider.slideTo(index);
                    el.classList.add(yearCssClass);
                } else {
                    el.classList.remove(yearCssClass);
                }
            });

            months.forEach(el => {
                if (el.dataset.timelineMonth === `${currentYear}-${currentMonth}`) {
                    el.classList.add(monthCssClass);
                } else {
                    el.classList.remove(monthCssClass);
                }
            });
        };

        const slider = new Swiper(el.querySelector('[data-timeline-slider]'), {
            navigation: {
                prevEl: el.querySelector('[data-timeline-slider-prev]'),
                nextEl: el.querySelector('[data-timeline-slider-next]'),
            },
            initialSlide: el.querySelectorAll('.swiper-slide').length - 1,
            centeredSlides: true,
            grabCursor: true,
            slidesPerView: 'auto',
            threshold: 10,
            watchOverflow: true,
            on: {
                resize: (instance) => updateYearsSlider(instance, 0),
                slideChange: updateYearsSlider,
            },
        });

        // Slide to the given slide on month click
        months.forEach(el => {
            el.addEventListener('click', e => {
                e.preventDefault();
                const [year, month] = e.currentTarget.dataset.timelineMonth.split('-');

                [...slider.el.querySelectorAll('.swiper-slide')].forEach((slide, index) => {
                    if (slide.dataset.timelineYear === year && slide.dataset.timelineMonth === month) {
                        slider.slideTo(index);
                    }
                });
            });
        });
    });

    // Initialize the form file inputs
    [...document.querySelectorAll('input[type="file"]')].forEach(el => {
        const label = el.labels[0];
        label.dataset.initialText = label.innerText;

        el.addEventListener('change', () => {
            label.innerText = el.value.split('\\').pop() || label.dataset.initialText;
        });
    });

    // News scroll offset
    [...document.querySelectorAll('.news-navigation a')].forEach(el => {
        el.addEventListener('click', () => {
            window.sessionStorage.setItem(newsScrollOffsetSessionKey, el.getBoundingClientRect().top);
        });
    });
});
