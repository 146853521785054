window.addEventListener('DOMContentLoaded', () => {
    if (window.localStorage.getItem('fusion') === '1') {
        return;
    }

    const popup = document.createElement('div');
    popup.classList.add('fusion');
    popup.innerHTML = `
<div class="fusion__inside">
    <button class="fusion__close" title="Schliessen"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill-rule="evenodd" d="m3.426 2.024.094.083L8 6.586l4.48-4.479a1 1 0 0 1 1.497 1.32l-.083.095L9.414 8l4.48 4.478a1 1 0 0 1-1.32 1.498l-.094-.083L8 9.413l-4.48 4.48a1 1 0 0 1-1.497-1.32l.083-.095L6.585 8 2.106 3.522a1 1 0 0 1 1.32-1.498Z"></path></svg></button>
    <div class="fusion__alert">📢</div>
    <div class="fusion__title">Wir sind jetzt ein stolzes Mitglied der SOWACOM-Familie!</div>
    <div class="fusion__text">Erfahren Sie mehr über unseren Zusammenschluss.</div>
    <a class="fusion__link" href="https://www.sowacom.ch/tinline-systems-gmbh-zur-sowacom-familie/" target="_blank">Jetzt mehr erfahren</a>
</div>`;

    popup.querySelectorAll('button, a').forEach((el) => {
        el.addEventListener('click', () => {
            window.localStorage.setItem('fusion', '1');
            popup.remove();
        })
    });

    document.body.append(popup);
});
